import React, { useState } from "react";

import axios from 'axios'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";

import {
    Grid,
    Input,
    InputAdornment,
    TextField,
    Button,
    Autocomplete,
    CircularProgress,
    Box,
    Select,
    MenuItem,
} from "@mui/material";
import { ReactComponent as CreditCard } from "../../../assets/icons/credit-card.svg";
import './styles.css';
import { useNavigate } from "react-router-dom";
import countries from "./country";
const CmLabel = (props) => {
    const { children, ...lProps } = props;
    return (
        <label style={{ color: '#6D7280', fontSize: '14px' }} {...lProps}>{children}</label>
    );
};

const FormPago = ({ formData, handleChange, handleExpireDate }) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        try {
            if (!stripe || !elements) {
                throw new Error("Stripe.js no está completamente cargado.");
            }

            // 1. Crear Payment Intent en el backend
            const { data } = await axios.post(`${REACT_APP_BASE_URL}/index.php?r=api/booking/payment`, {
                amount: 5000,
                currency: "usd"
            }, {
                headers: {
                    "Content-Type": "application/json",
                    'x-authorization-token': token
                }
            });

            // 2. Crear PaymentMethod con los elementos de tarjeta
            const cardNumberElement = elements.getElement(CardNumberElement);
            const cardExpiryElement = elements.getElement(CardExpiryElement);
            const cardCvcElement = elements.getElement(CardCvcElement);

            const cardElement = elements.getElement(CardNumberElement);

            const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(data.client_secret, {
                payment_method: {
                    card: cardElement,
                    billing_details: {
                        name: `${formData.name} ${formData.lastName}`,  // Aquí pasas los detalles de facturación
                        address: {
                            line1: formData.address,
                            country: formData.country,
                        },
                    },
                },
            });

            countries.sort((a, b) => a.name.localeCompare(b.name));

            if (stripeError) {
                console.log(stripeError);
                throw new Error(stripeError.message);
            }

            navigate('/confirmacion-reserva/TRKZ-1234'); // Redirige a la página de confirmación

            setSuccess(true); // Pago exitoso
        } catch (err) {
            console.log(err)
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };



    return (

        <form onSubmit={handleSubmit}>



            <Grid container spacing={3}
                sx={{
                    '& .MuiInputBase-input': {
                        p: '7px 10px',
                        bgcolor: '#F9FAFB',
                        border: '1px solid #F9FAFB'
                    }
                }}
            >
                <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="numCard">Detalles de tarjeta</CmLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid rgb(192, 192, 192)', // Aplica el borde aquí
                            borderRadius: '4px',
                            bgcolor: '#F9FAFB',
                            px: 1,
                            py: 1,
                        }}
                    >
                        <InputAdornment position="start">
                            <CreditCard style={{ width: 15, height: 15 }} />
                        </InputAdornment>
                        <Box sx={{ flex: 1 }}>
                            <CardNumberElement
                                options={{
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "#000",
                                            backgroundColor: "#F9FAFB",
                                            "::placeholder": { color: "#aab7c4" },
                                            padding: "10px 0px",
                                        },
                                        invalid: {
                                            color: "#fa755a",
                                            iconColor: "#fa755a",
                                        },
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.error) console.error(event.error.message);
                                }}
                            />
                        </Box>
                    </Box>
                    {/* <Input
                        startAdornment={
                            <InputAdornment position="start">
                                <CreditCard height={15} width={15} />
                            </InputAdornment>
                        }
                        sx={{
                            border: '1px solid rgb(192, 192, 192)',
                            borderRadius: '4px',
                            bgcolor: '#F9FAFB',
                            "&::before": { borderBottom: 'none!important' },
                            "&::after": { borderBottom: 'none' },
                            '& .MuiInputAdornment-root': { pl: '5px', mr: 0 },
                            '& .MuiInputBase-input': { pl: '5px' }
                        }}
                        name="numCard"
                        type="text"
                        placeholder="0000 0000 0000 0000"
                        value={formData.numCard}
                        onChange={handleChange}
                        inputProps={{ maxLength: 19 }}
                    /> */}
                </Grid>

                {/* <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="date">Fecha vencimiento</CmLabel>
                    <TextField
                        name="date"
                        type="text"
                        placeholder="MM/YY"
                        value={formData.date}
                        onChange={handleExpireDate}
                        inputProps={{ maxLength: 5 }}
                    />
                </Grid> */}

                <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="expiry-date">Fecha vencimiento</CmLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid rgb(192, 192, 192)',
                            borderRadius: '4px',
                            bgcolor: '#F9FAFB',
                            px: 1,
                            py: 1,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <CardExpiryElement
                                id="expiry-date"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "#000",
                                            "::placeholder": { color: "#aab7c4" },
                                        },
                                        invalid: {
                                            color: "#fa755a",
                                        },
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.error) console.error(event.error.message);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

                {/* <Grid item xs={12} md={3} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="cvv">CVV</CmLabel>
                    <TextField
                        name="cvv"
                        type="text"
                        placeholder="CVV"
                        value={formData.cvv}
                        onChange={handleChange}
                        inputProps={{ maxLength: 3 }}
                    />
                </Grid> */}

                <Grid item xs={12} md={3} sx={{ display: 'grid' }}>

                    <CmLabel htmlFor="cvc">CVV</CmLabel>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid rgb(192, 192, 192)',
                            borderRadius: '4px',
                            bgcolor: '#F9FAFB',
                            px: 1,
                            py: 1,
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <CardCvcElement
                                id="cvc"
                                options={{
                                    style: {
                                        base: {
                                            fontSize: "16px",
                                            color: "#000",
                                            "::placeholder": { color: "#aab7c4" },
                                        },
                                        invalid: {
                                            color: "#fa755a",
                                        },
                                    }
                                }}
                                onChange={(event) => {
                                    if (event.error) console.error(event.error.message);
                                }}
                            />
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="name">Nombre*</CmLabel>
                    <TextField
                        name="name"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.name}
                        onChange={(e) => { handleChange(e) }}
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="lastName">Apellidos*</CmLabel>
                    <TextField
                        name="lastName"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.lastName}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: 'grid' }}>
                    <CmLabel htmlFor="address">Dirección de facturación*</CmLabel>
                    <TextField
                        name="address"
                        type="text"
                        placeholder="Introduce..."
                        value={formData.address}
                        onChange={handleChange}
                    />
                </Grid>

                <Grid item xs={12} md={6} sx={{ display: "grid" }}>
                    <CmLabel htmlFor="country">País*</CmLabel>
                    <Select
                        labelId="country-select-label"
                        id="country-select"
                        name="country"
                        value={formData.country || ""}
                        onChange={(event) => handleChange(event)}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country.code} value={country.code}>
                                {country.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        fullWidth
                    >
                        {loading ? <CircularProgress size={24} /> : "Pagar"}
                    </Button>
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                    {success && <p style={{ color: 'green' }}>Pago exitoso</p>}
                </Grid>
            </Grid>
        </form>
    );
};

export default FormPago;
