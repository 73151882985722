const countries = [
    { code: "AG", name: "Antigua y Barbuda" },
    { code: "AR", name: "Argentina" },
    { code: "AU", name: "Australia" },
    { code: "AT", name: "Austria" },
    { code: "BS", name: "Bahamas" },
    { code: "BH", name: "Baréin" },
    { code: "BB", name: "Barbados" },
    { code: "BE", name: "Bélgica" },
    { code: "BZ", name: "Belice" },
    { code: "BM", name: "Bermuda" },
    { code: "BO", name: "Bolivia" },
    { code: "BW", name: "Botsuana" },
    { code: "BR", name: "Brasil" },
    { code: "BG", name: "Bulgaria" },
    { code: "CM", name: "Camerún" },
    { code: "CA", name: "Canadá" },
    { code: "CL", name: "Chile" },
    { code: "CN", name: "China" },
    { code: "CO", name: "Colombia" },
    { code: "CR", name: "Costa Rica" },
    { code: "HR", name: "Croacia" },
    { code: "CY", name: "Chipre" },
    { code: "CZ", name: "República Checa" },
    { code: "DK", name: "Dinamarca" },
    { code: "DM", name: "Dominica" },
    { code: "DO", name: "República Dominicana" },
    { code: "EC", name: "Ecuador" },
    { code: "EG", name: "Egipto" },
    { code: "SV", name: "El Salvador" },
    { code: "EE", name: "Estonia" },
    { code: "FI", name: "Finlandia" },
    { code: "FJ", name: "Fiyi" },
    { code: "FR", name: "Francia" },
    { code: "GE", name: "Georgia" },
    { code: "DE", name: "Alemania" },
    { code: "GH", name: "Ghana" },
    { code: "GR", name: "Grecia" },
    { code: "GD", name: "Granada" },
    { code: "GT", name: "Guatemala" },
    { code: "GY", name: "Guyana" },
    { code: "HN", name: "Honduras" },
    { code: "HK", name: "Hong Kong" },
    { code: "HU", name: "Hungría" },
    { code: "IS", name: "Islandia" },
    { code: "IN", name: "India" },
    { code: "ID", name: "Indonesia" },
    { code: "IE", name: "Irlanda" },
    { code: "IL", name: "Israel" },
    { code: "IT", name: "Italia" },
    { code: "ES", name: "España" },
    { code: "JM", name: "Jamaica" },
    { code: "JP", name: "Japón" },
    { code: "JO", name: "Jordania" },
    { code: "KE", name: "Kenia" },
    { code: "KW", name: "Kuwait" },
    { code: "LV", name: "Letonia" },
    { code: "LT", name: "Lituania" },
    { code: "LU", name: "Luxemburgo" },
    { code: "MG", name: "Madagascar" },
    { code: "MY", name: "Malasia" },
    { code: "MT", name: "Malta" },
    { code: "MU", name: "Mauricio" },
    { code: "MX", name: "México" },
    { code: "MA", name: "Marruecos" },
    { code: "NA", name: "Namibia" },
    { code: "NL", name: "Países Bajos" },
    { code: "NZ", name: "Nueva Zelanda" },
    { code: "NG", name: "Nigeria" },
    { code: "NO", name: "Noruega" },
    { code: "OM", name: "Omán" },
    { code: "PA", name: "Panamá" },
    { code: "PY", name: "Paraguay" },
    { code: "PE", name: "Perú" },
    { code: "PH", name: "Filipinas" },
    { code: "PL", name: "Polonia" },
    { code: "PT", name: "Portugal" },
    { code: "QA", name: "Catar" },
    { code: "RO", name: "Rumania" },
    { code: "RW", name: "Ruanda" },
    { code: "KN", name: "San Cristóbal y Nieves" },
    { code: "LC", name: "Santa Lucía" },
    { code: "VC", name: "San Vicente y las Granadinas" },
    { code: "SA", name: "Arabia Saudita" },
    { code: "SN", name: "Senegal" },
    { code: "SG", name: "Singapur" },
    { code: "SK", name: "Eslovaquia" },
    { code: "SI", name: "Eslovenia" },
    { code: "ZA", name: "Sudáfrica" },
    { code: "KR", name: "Corea del Sur" },
    { code: "ES", name: "España" },
    { code: "SE", name: "Suecia" },
    { code: "CH", name: "Suiza" },
    { code: "TW", name: "Taiwán" },
    { code: "TZ", name: "Tanzania" },
    { code: "TH", name: "Tailandia" },
    { code: "TT", name: "Trinidad y Tobago" },
    { code: "TN", name: "Túnez" },
    { code: "UG", name: "Uganda" },
    { code: "AE", name: "Emiratos Árabes Unidos" },
    { code: "GB", name: "Reino Unido" },
    { code: "US", name: "Estados Unidos" },
    { code: "UY", name: "Uruguay" },
    { code: "UZ", name: "Uzbekistán" },
    { code: "VN", name: "Vietnam" },
    { code: "ZM", name: "Zambia" }
];


export default countries;