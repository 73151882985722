import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { PersistGate } from 'redux-persist/integration/react';

import reportWebVitals from "./reportWebVitals"
import { ThemeProvider } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "./theme"
import { store, persistor } from "./app/store"
import { Provider } from "react-redux"

const rootElement = document.getElementById("root")
const root = ReactDOM.createRoot(rootElement)

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
