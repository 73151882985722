import React, { useEffect } from "react"
import { Box, Typography } from "@mui/material"
import TagButton from "../TagButton"
import { addTour } from '../../app/features/cartSlice'
import { useDispatch } from "react-redux"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"


const PaymentSummary = ({
    items,
    iva = 0,
    tour,
    date
}) => {

    const [totalPrice, setTotalPrice] = React.useState(0)

    const navigate = useNavigate();

    useEffect(() => {
        const total = items.reduce((total, item) => total + (item.price * item.value), 0);
        setTotalPrice(total)
    }, [items])

    const dispatch = useDispatch();


    const addToCart = () => {

        const getCoverOrDefault = (array, condition) => {
            return array.find(condition) || array[0] || null;
        };

        const condition = image => image.isCover;

        let cover = getCoverOrDefault(tour.images, condition);

        const tourtoBook = {
            name: tour.name,
            slug: tour.slug,
            city: tour.city.slug,
            cover: cover.imagen,
            booking: items,
            total: totalPrice,
            date: dayjs(date).format('YYYY-MM-DD')
        }
        dispatch(addTour(tourtoBook));
        navigate('/checkout')
    }

    return (
        <Box sx={{
            backgroundColor: '#F0F0F1',
            borderRadius: "5px",
            p: "1.2rem",
        }}>
            <Typography color="secondary" variant="h5" mb={1}>
                Resumen
            </Typography>
            {
                items.map((item, index) => (
                    <Box
                        key={`${item.title}${index}`}
                        sx={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            mb: ".5rem",
                        }}
                    >
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.title}
                        </Typography>
                        <Typography
                            color="secondary"
                            sx={{
                                fontSize: 14,
                                fontFamily: "inter",
                            }}
                        >
                            {item.price * item.value} €
                        </Typography>
                    </Box>
                ))
            }
            <Box sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                mt: "1.5rem",
            }}
            >
                <Typography color="secondary" sx={{ lineHeight: 1 }}>
                    Total:
                </Typography>
                <Typography color="secondary" variant="h5" sx={{ lineHeight: 1 }}>
                    {totalPrice}€
                </Typography>
            </Box>
            <Typography
                color="grayText"
                sx={{
                    fontSize: 12,
                    fontFamily: "inter",
                    textAlign: "right",
                    mb: "1.5rem",
                }}
            >
                {totalPrice * iva / 100}€ IVA
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <TagButton
                    text="Seguir personalizando mi reserva"
                    onClick={addToCart}
                    id='personalizar-btn'
                    sx={{
                        borderRadius: "2rem",
                        px: "1.5rem",
                        fontSize: 16,
                        fontFamily: 'Poppins',
                        fontWeight: 600,
                        lineHeight: 1.5,
                        mb: "1rem",
                        py: { xs: "1rem", md: "3px" },
                    }}
                />
            </Box>
        </Box>
    )
}

export default PaymentSummary
