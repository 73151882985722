import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session'; // Utiliza localStorage por defecto
import cartReducer from '../app/features/cartSlice';

// Configuración de persistencia
const persistConfig = {
  key: 'cart', // Clave única para el estado persistido
  storage,     // Define dónde se almacena (localStorage en este caso)
};

// Crea el persistReducer
const persistedCartReducer = persistReducer(persistConfig, cartReducer);

// Configuración del store con el reducer persistido
export const store = configureStore({
  reducer: {
    cart: persistedCartReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], // Ignorar las acciones de redux-persist
      },
    }),
});

// Persistor para inicializar persistencia
export const persistor = persistStore(store);


