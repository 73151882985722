import React, { useEffect, useState } from "react";
import {
    AppBar,
    Box,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Pagination,
    Select,
    Toolbar,
    Typography
} from "@mui/material";
import { ArrowBack, NavigateNext } from '@mui/icons-material';
import { useParams } from "react-router-dom";
import Hero2 from "../../components/DestinationHero";
import Filters from "../../components/Filters";
import ProductCard2 from "../../components/DestinationProductCard";
import { dataPage } from "../../utils/destinationPageMockData";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg"
import MainLayout from "../../components/MainLayout";
import useFetchTourList from "../../hooks/API/Tours/useFetchTourList";
import axios from "axios";



const DynamicContent = ({ content }) => {
    return (
        content.map(item => (
            <Typography
                key={item.value}
                sx={{ py: '10px' }}
                variant={item.variant}
                component={item.component}
            >{item.value}</Typography>
        ))
    )
}

const GroupLinks = ({ links }) => (
    links.map(link => (
        <Button
            key={link.text}
            sx={{
                fontSize: '14px',
                mx: '5px',
                my: '6px'
            }}
            variant="contained"
            href={link.href}
        >{link.text}</Button>
    ))
)

const TourList = () => {
    let params = useParams();
    const [orderBy, setOrderBy] = useState('precio');
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [tours, setTours] = React.useState([]);
    const [destination, setDestination] = React.useState({});
    const [error, setError] = React.useState(null);
    const [hero, setHero] = React.useState({});
    const [totalReviews, setTotalReviews] = React.useState(0);
    const [totalTours, setTotalTours] = React.useState(0);
    const [breadcrumbs, setBreadcrumbs] = React.useState([]);
    const [content, setContent] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const {
        activities,
        description,
        numberActivities,
        atracciones,
        guias } = dataPage;
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const fetchTourList = async ({ name }) => {
        setIsLoading(true)
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');


        try {
            const { data } = await axios.get(
                `${REACT_APP_BASE_URL}/index.php?r=api/tours/tourlist&city=${name}`,
                {
                    headers: {
                        'x-authorization-token': token
                    }
                }
            )

            setIsLoading(false)

            if (data?.tours) {
                setTours(data.tours)
                setDestination(data.city)
                let totalReviewsCount = 0;
                let rating = 0;
                data.tours.forEach(tour => {
                    const reviews = tour.reviewsTotal;
                    totalReviewsCount += reviews;

                    if (reviews >= 0 && reviews < 250) {
                        rating += 7;
                    } else if (reviews >= 250 && reviews <= 1000) {
                        rating += 9;
                    } else if (reviews > 1000) {
                        rating += 10;
                    }

                })

                const totalToursRating = rating / data.tours.length;

                setHero({
                    country: data.country.name,
                    city: data.city.name,
                    numberComments: totalReviewsCount,
                    media: `${totalToursRating}/10`,
                    numberActivities: data.count,
                    bgImage: data.city.image
                });

                setContent(data.city.content);

                setTotalTours(data.count);
                setBreadcrumbs([
                    {
                        text: 'Incio',
                        href: '/',
                        isCurrent: false
                    },
                    {
                        text: 'Destinos',
                        href: '/destinos',
                        isCurrent: false
                    },
                    {
                        text: data.country.name,
                        href: `/destinos/${data.country.slug}`,
                        isCurrent: false
                    },
                    {
                        text: data.city.name,
                        href: '#',
                        isCurrent: true
                    }
                ]);
            }
        } catch (err) {
            console.error('Error fetching tour details ', err)
            setError(true)
            setIsLoading(false)
        }
    }


    useEffect(() => {
        fetchTourList({ name: params.city });
    }, []);

    return (
        <MainLayout>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                color="secondary"
            >
                <AppBar sx={{
                    pr: "0!important",
                    position: 'relative',
                    backgroundColor: '#ffffff'
                }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="primary"
                            onClick={handleClose}
                            aria-label="close"

                        >
                            <ArrowBack />
                        </IconButton>
                    </Toolbar>
                    <Box sx={{}}>
                        <Filters />
                    </Box>
                </AppBar>
            </Dialog>
            <Hero2 {...hero} />
            <Container sx={{ maxWidth: 'none!important', pt: '50px', mx: '0px', backgroundColor: '#F0F0F1' }}>
                <Grid container spacing={4}>
                    <Grid item xs={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Filters />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        {breadcrumbs && <Breadcrumbs
                            separator={<NavigateNext fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{ pb: '20px' }}
                        >
                            {breadcrumbs.map(item => (
                                <Link
                                    key={item.text}
                                    underline="hover"
                                    color={item.isCurrent ? "secondary" : "primary"}
                                    href={item.href}
                                    sx={{
                                        fontWeight: 500,
                                    }}
                                >
                                    {item.text}
                                </Link>
                            ))}
                        </Breadcrumbs>}
                        <Typography
                            sx={{ fontSize: '14px' }}
                            variant="body2"
                            component="p"
                        >
                            {destination.shortDescription}
                        </Typography>
                        <Divider sx={{ pt: '15px' }} />
                        <Box sx={{ pt: '20px' }}>
                            <Box
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                    mb: '15px'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px' }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {totalTours} actividades en {hero.city}
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    pb: "20px",
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}>
                                <Typography
                                    sx={{ fontSize: '14px', display: { xs: 'none', md: 'block' } }}
                                    color="#666B6E"
                                    variant="body2"
                                    component="h2"
                                >
                                    {totalTours} actividades en {hero.city}
                                </Typography>
                                <Button
                                    onClick={handleClickOpen}
                                    className="d-none d-sm-block"
                                    sx={{
                                        display: { xs: 'block', md: 'none' },
                                        color: '#666B6E',
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: '5px',
                                        fontWeight: '200'
                                    }}
                                >
                                    <Filter />&nbsp;Filtros
                                </Button>
                                <FormControl>
                                    <InputLabel id="order-by-labe">Ordenar por</InputLabel>
                                    <Select
                                        sx={{ width: '120px', height: '40px' }}
                                        labelId="order-by-label"
                                        id="order-by-labe"
                                        value={orderBy}
                                        label="Ordenar por"
                                        onChange={() => { }}
                                    >
                                        <MenuItem value='precio'>Precio</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {(tours.length > 0) && tours.map((activity, index) => (
                                <Link
                                    key={index}
                                    underline="none"
                                    href={`/tour/${activity.city.slug}/${activity.slug}`}
                                >
                                    <ProductCard2 key={index} {...activity} />
                                </Link>
                            ))}
                            {/* <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    pt: '15px'
                                }}>
                                <Pagination
                                    count={100}
                                    page={page}
                                    variant="outlined"
                                    color="primary"
                                    onChange={handleChangePage}
                                />
                            </Box> */}
                            <Card id="card-city-summary" sx={{ mt: '30px' }}>
                                <CardContent>
                                    {/* <DynamicContent content={content} /> */}
                                    <div dangerouslySetInnerHTML={{ __html: content }} />
                                </CardContent>
                            </Card>
                            {/* <Box
                                sx={{
                                    my: '30px',
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold'
                                    }}
                                    variant="h5"
                                    component='h2'
                                >{atracciones.title}</Typography>
                                <Box sx={{ pt: '20px' }}>
                                    <GroupLinks links={atracciones.atraccionesLinks} />
                                </Box>
                            </Box> */}
                            {/* <Box
                                sx={{
                                    my: '30px',
                                    textAlign: 'center'
                                }}>
                                <Typography
                                    sx={{
                                        fontWeight: 'bold'
                                    }}
                                    variant="h5"
                                    component='h2'
                                >{guias.title}</Typography>
                                <Typography
                                    variant="body2"
                                    component="p"
                                >
                                    {guias.description}
                                </Typography>

                            </Box> */}
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </MainLayout>
    )
}

export default TourList;