import React, { useEffect, useState } from "react";

import { Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, Modal, Typography } from "@mui/material";
import { ReactComponent as Users } from '../../assets/icons/users.svg'
import { ReactComponent as Edit } from '../../assets/icons/edit-3.svg'
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg'
import { ReactComponent as Close } from '../../assets/icons/close-x.svg'
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-rigth.svg";
import Step1 from "./step1";
import Step2 from "./step2";
import Step3 from "./step3";
import EditAssistants from "./editAssistants";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers";
import { getTotalAssistants } from "../../utils/checkout";

dayjs.locale('es');

const style = {
    position: 'absolute',
    display: 'flex',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 600,
    bgcolor: 'background.paper',
    borderRadius: '8px'
};

const ASSISTANTS_LABELS = [
    {
        clave: 'Adulto',
        label: 'Adultos',
        parameters: '(+12 años)'
    },
    {
        clave: 'Infante',
        label: 'Niños',
        parameters: '(2 - 11 años)'
    },
    {
        clave: 'Bebe',
        label: 'Bebé',
        parameters: '(0 - 2 años)'
    }
]

const UpdateTourModal = ({ open, handleClose, tour }) => {
    const [step, setStep] = useState(1);
    const [persons, setPersons] = useState({ Adulto: 0, Infante: 0, Bebe: 0 });
    const [showEditAssistants, setShowEditAssistants] = useState(false);
    const [showEditDate, setShowEditDate] = useState(false);

    const handleShowEditDate = () => setShowEditDate(!showEditDate);
    const handleShowEditAssistants = () => setShowEditAssistants(!showEditAssistants);
    const handleNextStep = () => setStep(step + 1);
    const handlePreviousStep = () => setStep(step - 1);
    const {
        name,
        cover,
        date = '',
        booking
    } = tour;



    const handleChangeCalendar = (newDate) => {
        console.log(newDate)
    }

    const handleDecrement = (clave) => {
        if (persons[clave] > 0) {
            setPersons({ ...persons, [clave]: persons[clave] - 1 })
        }
    }

    const handleIncrement = (clave) => {
        setPersons({ ...persons, [clave]: persons[clave] + 1 })
    }

    // useEffect(() => {
    //     let personsAux = { Adulto: 0, Infante: 0, Bebe: 0 };

    //     pax.map(item => personsAux[item.name] = item.total);

    //     setPersons(personsAux);
    // }, [pax])

    const image = cover;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
        >
            <Card sx={style}>
                <CardMedia
                    sx={{
                        width: "40%",
                        height: '100%',
                        position: 'relative',

                    }}
                    image={image}
                    title={name}
                >
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        bgcolor: 'rgba(62, 58, 57, 50%)',
                        left: 0,
                        top: 0
                    }} />
                    <Box sx={{
                        width: '100%',
                        height: '100%',
                        px: '20px',
                        pt: '30px',
                        zIndex: 2,
                        position: 'relative',
                    }}>
                        <Typography
                            variant="h5"
                            sx={{
                                fontSize: '18px',
                                color: 'primary.contrastText',
                            }}
                        >
                            {name}
                        </Typography>
                        <Box sx={{
                            bgcolor: '#FFFFFF',
                            width: '86%',
                            height: 'auto',
                            borderRadius: '8px',
                            position: 'absolute',
                            left: '20px',
                            bottom: '20px',
                            p: '12px'
                        }}>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: 'center',
                                py: '5px'
                            }}>
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <Users />
                                    <Box sx={{
                                        pl: '5px'
                                    }}>
                                        <Typography sx={{
                                            fontSize: '13px',
                                            color: 'secondary.main',
                                            fontWeight: 600
                                        }}>
                                            Asistentes
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '11px',
                                            color: 'secondary.main',
                                            fontWeight: 500
                                        }}>
                                            {/* {`${getTotalAssistants(pax)} Personas`} */}
                                        </Typography>
                                    </Box>
                                </Box>
                                <IconButton
                                    aria-label="edit assitants"
                                    onClick={handleShowEditAssistants}
                                >
                                    <Edit />
                                </IconButton>
                            </Box>
                            {showEditAssistants && <Box sx={{
                                px: '20px'
                            }}>
                                {ASSISTANTS_LABELS.map(item => (
                                    <EditAssistants
                                        key={item.clave}
                                        label={item.label}
                                        parameters={item.parameters}
                                        handleDecrement={handleDecrement}
                                        handleIncrement={handleIncrement}
                                        value={persons[item.clave]}
                                        clave={item.clave}
                                    />
                                ))}
                            </Box>}
                            <Box sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: 'center',
                                py: '5px'
                            }}>
                                <Box sx={{
                                    display: 'flex'
                                }}>
                                    <Calendar />
                                    <Box sx={{
                                        pl: '5px'
                                    }}>
                                        <Typography sx={{
                                            fontSize: '13px',
                                            color: 'secondary.main',
                                            fontWeight: 600
                                        }}>
                                            Fecha
                                        </Typography>
                                        <Typography sx={{
                                            fontSize: '11px',
                                            color: 'secondary.main',
                                            fontWeight: 500
                                        }}>
                                            {dayjs(date).format('MMMM D, YYYY')}
                                        </Typography>
                                    </Box>
                                </Box>
                                <IconButton
                                    aria-label="edit date"
                                    onClick={handleShowEditDate}
                                >
                                    <Edit />
                                </IconButton>
                            </Box>
                            {showEditDate && <Box>
                                <DateCalendar
                                    sx={{
                                        width: 'auto',
                                        height: 'auto'
                                    }}
                                    value={dayjs(date)}
                                    onChange={(newValue) => handleChangeCalendar(newValue)}
                                />
                            </Box>}
                        </Box>
                    </Box>
                </CardMedia>
                <CardContent sx={{
                    width: '60%',
                    pt: '30px',
                    position: 'relative'
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                        <Typography
                            variant="body2"
                            component="p"
                            sx={{
                                color: 'secondary.main'
                            }}
                        >
                            Paso {step} de 3
                        </Typography>
                        <IconButton
                            aria-label="Close modal"
                            onClick={handleClose}
                            sx={{
                                p: 0,
                            }}
                        >
                            <Close height={18} width={18} />
                        </IconButton>
                    </Box>
                    {step == 1 && <Step1 />}
                    {step == 2 && <Step2 />}
                    {step == 3 && <Step3 />}
                    <Box
                        sx={{
                            position: 'absolute',
                            left: '16px',
                            bottom: '20px',
                            width: '90%',
                            display: 'flex',
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{
                                width: '180px'
                            }}
                        >Guardar cambios</Button>
                        <Box>
                            {step !== 1 && <IconButton
                                aria-label="Previous step"
                                onClick={handlePreviousStep}
                                sx={{
                                    pr: '10px'
                                }}
                            >
                                <ArrowLeft width={20} height={20} />
                            </IconButton>}
                            {step !== 3 && <IconButton
                                aria-label="Previous step"
                                onClick={handleNextStep}
                                sx={{
                                    pl: '10px'
                                }}
                            >
                                <ArrowRight width={20} height={20} />
                            </IconButton>}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Modal>
    )
}

export default UpdateTourModal;
