import React from 'react'
import Hero from '../../components/Hero';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Container } from '@mui/material';
import CardsGrid from '../../components/CardsGrid';
import MainLayout from '../../components/MainLayout';
import { opacities } from '../../components/BlurImageWrapper';
import { useParams } from 'react-router-dom';
import useFetchCities from '../../hooks/API/Destinations/useFetchCities';

const DestinationsCountry = () => {

    const params = useParams();
    const country = params.country;
    const { cities, isLoading } = useFetchCities({ country });
    const [gridCards, setGridCards] = React.useState([]);
    const [heroImage, setHeroImage] = React.useState('');
    const [countryName, setCountryName] = React.useState('');
    // const [gridCards, setGridCards] = React.useState([]);

    /*
    {"name":"Paris","activities":90000,"path":"destinos/francia/paris","image":"/static/media/paris.d8ac660d8d3cb4e320ad.png"}
    */
    React.useEffect(() => {
        if (isLoading) return;

        if (cities?.cities) {
            const allcities = cities?.cities.map(item => {
                return {
                    name: item.name,
                    activities: item.total_tours,
                    path: `/tours/${item.slug}`,
                    image: item.image
                }
            });

            setHeroImage(cities?.country?.image);
            setCountryName(cities?.country?.name);

            setGridCards(allcities)
        }



    }, [cities, isLoading])

    return (
        <MainLayout>
            <Hero
                bgImage={heroImage}
                opacity={opacities.lighter}
                renderInputSearch={false}
                title={countryName}
                titleHeader='Tours privados'
            />
            <Container>
                <Breadcrumbs breadcrumbs={[
                    {
                        name: 'Destinos',
                        path: '/destinos'
                    },
                    {
                        name: countryName,
                        path: '#'
                    }
                ]} />
                {gridCards && <CardsGrid cards={gridCards} />}
            </Container>
        </MainLayout>
    )
}

export default DestinationsCountry
