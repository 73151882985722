import React, { useCallback, useEffect, useState } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import Resumen from './resumen'
import { useSelector } from 'react-redux'
import { selectTours } from '../../../app/features/cartSlice'
import CardResumen from '../cardResumen'
import FormPago from './formPago'
import { ReactComponent as Visa } from '../../../assets/icons/visa-color.svg'
import { ReactComponent as MasterCard } from '../../../assets/icons/mastercard-color.svg'
import { ReactComponent as Maestro } from '../../../assets/icons/maestro-color.svg'
import { ReactComponent as AmericanExpress } from '../../../assets/icons/american-express-color.svg'
import Agreements from './agreements'
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Step3 = () => {
    const cartTours = useSelector(state => state.cart.checkout.tours)
    const [isFormValid, setIsFormValid] = useState(false)
    const [agreement1, setAgreement1] = useState(false)
    const [agreement2, setAgreement2] = useState(false)

    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const handleAgreements = () => { }
    const handleNext = () => { }

    const [formData, setFormData] = useState({
        numCard: '',
        date: '',
        cvv: '',
        name: '',
        lastName: '',
        address: '',
        country: 'ES'
    })

    const [errors, setErrors] = useState({
        numCard: '',
        date: '',
        cvv: '',
        name: '',
        lastName: '',
        address: '',
        country: ''
    })

    const formatCardNumber = (value) => {
        // Elimina todos los caracteres que no sean dígitos
        const digits = value.replace(/\D/g, "");
        // Agrupa los dígitos en grupos de 4
        return digits.replace(/(\d{4})(?=\d)/g, "$1 ");
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        const tmp = {
            ...formData,
            [name]: value,
        };
        setFormData(tmp)
    }

    const formatExpirationDate = (value) => {
        // Elimina caracteres que no sean números
        const digits = value.replace(/\D/g, "");
        // Inserta '/' después de los dos primeros dígitos
        return digits.length > 2 ? `${digits.slice(0, 2)}/${digits.slice(2, 4)}` : digits;
    };

    const handleExpireDate = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: name === "date" ? formatExpirationDate(value) : value,
        });
    };

    const validateForm = useCallback(() => {
        const newErrors = {
            numCard: '',
            date: '',
            cvv: '',
            name: '',
            lastName: '',
            address: '',
            country: ''
        }

        let isValid = true
        if (!formData.numCard) {
            newErrors.numCard = 'El numero de tarjeta es requerido'
            isValid = false
        }
        if (!formData.date) {
            newErrors.date = 'La fecha de vencimiento es requerida'
            isValid = false
        }
        if (!formData.cvv) {
            newErrors.cvv = 'El CVV requerido'
            isValid = false
        }

        if (!formData.name) {
            newErrors.name = 'El nombre es requerido'
            isValid = false
        }

        if (!formData.lastName) {
            newErrors.lastName = 'Los apellidos son requeridos'
            isValid = false
        }
        if (!formData.address) {
            newErrors.address = 'La direccion es requerida'
            isValid = false
        }
        if (!formData.country) {
            newErrors.country = 'El pais es requerido'
            isValid = false
        }

        setErrors(newErrors)
        setIsFormValid(isValid)
    }, [formData])

    useEffect(() => {
        validateForm()
    }, [validateForm])
    return (
        <Grid container spacing={3} sx={{ my: '30px' }}>
            <Grid item xs={12} md={8}>
                <Box
                    sx={{
                        backgroundColor: '#ffffff',
                        py: '15px',
                        px: { xs: 0, md: '15px' },
                        borderRadius: '8px'
                    }}>
                    <Box sx={{ pt: '25px' }}>
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Box>
                                <Typography
                                    variant="h5"
                                    color="secondary"
                                    sx={{
                                        fontSize: { xs: '12px', md: '18px' }
                                    }}>
                                    Pago con tarjeta
                                </Typography>
                                <Typography
                                    variant="body2"
                                    component="p"
                                    sx={{
                                        fontSize: { xs: '8px', md: '10px' },
                                        pb: '10px'
                                    }}>
                                    Powered by Stripe
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                    justifyContent: 'start',
                                    px: 0,
                                    gap: '.5rem'
                                }}>
                                <Visa height={25} width={45} />
                                <MasterCard height={25} width={45} />
                                <Maestro height={25} width={45} />
                                <AmericanExpress height={25} width={45} />
                            </Box>
                            <Box
                                sx={{
                                    display: { xs: 'flex', md: 'flex' },
                                    justifyContent: 'start',
                                    px: 0,
                                    gap: '.1rem'
                                }}>
                                <Visa height={30} width={45} />
                                <MasterCard height={15} width={30} />
                                <Maestro height={15} width={30} />
                                <AmericanExpress height={15} width={30} />
                            </Box>
                        </Box>
                        <Elements stripe={stripePromise}>
                            <FormPago
                                formData={formData}
                                handleChange={handleChange}
                                handleExpireDate={handleExpireDate}
                            />
                        </Elements>
                    </Box>
                </Box>
            </Grid>

            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    display: { xs: 'none', md: 'block' }
                }}>
                <Resumen cartTours={cartTours} />
            </Grid>
            <Grid
                item
                xs={12}
                md={4}
                sx={{
                    pl: { xs: '0!important' },
                    display: { xs: 'block', md: 'none' },
                    width: '100%'
                }}>
                <CardResumen
                    isFormValid={isFormValid}
                    handleNext={handleNext}
                />
            </Grid>
        </Grid>
    )
}

export default Step3
