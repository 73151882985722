import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import ProductCard3 from "../../../components/ProductCard3";
import { Link, useNavigate } from "react-router-dom";

const Recomendations = ({ recomendedTours, city }) => {

    const navigate = useNavigate();

    return (
        <Box>
            <Typography
                variant="h2"
                component="h2"
                sx={{
                    color: 'secondary.main'
                }}
            >
                ¿Vas a {recomendedTours[0].city.name}? Aquí hay algo que te puede interesar
            </Typography>
            <Grid container spacing={1.5} sx={{
                my: '30px'
            }}>
                {recomendedTours.map(item => (
                    <Grid item xs={4} key={item.id}>
                        <Link style={{ textDecoration: 'none' }} to={`/tour/${item.city.slug}/${item.slug}`}>
                            <ProductCard3 {...item} />
                        </Link>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                mb: '40px'
            }}>
                <Button
                    onClick={() => navigate(`/tours/${recomendedTours[0].city.slug}`)}
                    variant="outlined"
                    sx={{
                        color: 'primary.main'
                    }}
                >Ver más tours</Button>
            </Box>
        </Box>
    )
}

export default Recomendations;