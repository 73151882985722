import React from "react";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from "react-redux";

const ResumenAccordion = ({ total = 300, iva = 25 }) => {

    const products = useSelector(state => state.cart.checkout.tours);
    const totalBooking = products.reduce((accumulator, current) => accumulator + current.total, 0);
    const totalIva = totalBooking * (iva / 100)
    return (
        <>
            <Accordion
                sx={{
                    '.Mui-expanded': {
                        minHeight: 'unset!important',
                    },
                    "::before": {
                        backgroundColor: 'unset'
                    },
                    '& .MuiDivider-root': {
                        display: 'none'
                    },
                    boxShadow: 'none',
                    mb: '0!important'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel-resumen-content`}
                    id={`panel-resumen-header`}
                    sx={{
                        '.Mui-expanded': {
                            my: '0!important',
                        },
                        '& .MuiAccordionSummary-content': {
                            my: 0
                        },
                        minHeight: 'unset',
                        color: 'secondary.main',
                        fontWeight: 'bold',
                        px: 0,
                        borderBottom: '1px solid #223F7F',
                    }}
                >
                    Resumen de compra
                </AccordionSummary>
                {products && products.map(f => {
                    return (<AccordionDetails
                        key={f.name}
                        sx={{
                            p: '0'
                        }}
                    >
                        <Box
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                        >
                            <Typography sx=
                                {{
                                    color: 'secondary.main',
                                    fontWeight: 200,
                                    fontSize: '14px'
                                }}
                            >{f.name}</Typography>
                            <Box>
                                <Typography
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 500,
                                        color: 'secondary.main'
                                    }}>
                                    {`${f.total}€`}
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>)
                })}

            </Accordion>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    paddingTop: '10px',
                    justifyContent: 'space-between',
                }}
            >
                <Typography sx=
                    {{
                        color: 'secondary.main',
                        fontWeight: 200,
                        fontSize: '14px'
                    }}
                >Total:</Typography>
                <Box>
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            color: 'secondary.main'
                        }}>
                        {`${totalBooking}€`}
                    </Typography>
                    <Typography
                        color="grayText"
                        sx={{
                            textAlign: 'right',
                            fontSize: '12px',
                            fontWeight: 400
                        }}>
                        {`${totalIva}€ IVA`}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default ResumenAccordion;