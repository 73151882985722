import React, { useCallback, useEffect, useState, useRef } from "react"
import {
    Alert,
    Box,
    Container,
    Divider,
    Typography
} from "@mui/material"
import dayjs from "dayjs"

import MainLayout from "../../components/MainLayout"
import Breadcrumbs from "../../components/Breadcrumbs"
import TagButton from "../../components/TagButton"
import ReviewRatings from "../../components/Tours/ReviewRatings"
import GridGallery from "../../components/GridGallery"
import DetailsSection from "../../components/Tours/DetailsSection"
import ItineraryItem from "../../components/Tours/ItineraryItem"
import Calendar from "../../components/Filters/Calendar"
import RadioButtonsFilter from "../../components/Filters/RadioButtonsFilter"
import AddItemsButtonsFilter from "../../components/Filters/AddItemsButtonsFilter"
import PaymentSummary from "../../components/Tours/PaymentSummary"
import CouponBox from "../../components/Tours/CouponBox"
import AdditionalInformationSection from "../../components/Tours/AdditionalInformationSection"
import FAQs from "../../components/Tours/FAQs"
import MediaCard from "../../components/ProductCard3"
import Reviews from "../../components/Tours/Reviews"

import { useScreenDetector } from "../../hooks/useScreenDetector"

import { ReactComponent as MedalIcon } from "../../assets/icons/medal.svg"
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg"
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg"

import { tourData, topActivities, reviews } from "./tourData"
import { dataPage } from "../../utils/destinationPageMockData"
import useFetchTourDetails from "../../hooks/API/Tours/useFetchTourDetails"
import RichText from "../../components/RichText"
import { Link, useParams } from "react-router-dom"
import ErrorPage from "../Error"
import useQuote from "../../hooks/API/Tours/useQuote"
import axios from "axios"
import useFetchSuggestTours from "../../hooks/API/Tours/useFetchSuggestTours"


const Tour = () => {
    const params = useParams();

    const { tourDetails, isLoading, error } = useFetchTourDetails(params.slug);
    const [tour, setTour] = useState({});
    const [expandedTabs, setExpandedTabs] = React.useState([])
    const [addedItineraries, setAddedItineraries] = React.useState([]);
    const [date, setDate] = React.useState(dayjs(new Date()).add(3, 'day'));
    const [quote, setQuote] = useState(null);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [availableDate, setAvailableDate] = useState(true);
    const [fetchParams, setFetchParams] = useState(null);
    const [recommendations, setRecommendations] = useState(null);
    const suggestedTours = useFetchSuggestTours(fetchParams);

    const [participants, setParticipans] = React.useState([])
    const handleScrollToTop = () => {
        window.scrollTo(0, 0); // Mueve la pantalla al inicio (x = 0, y = 0)
    };

    const targetRef = useRef(null);



    const AvailabilityButton = ({ styles = {} }) => (
        <TagButton
            text="Ver disponibilidad"
            href="#personalizar-btn"
            onClick={(e) => {
                e.preventDefault();
                console.log("looking for availability")
                targetRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
            }}
            sx={{
                fontWeight: 600,
                mt: "1rem",
                px: "1rem",
                py: ".4rem",
                textAlign: 'center',
                width: "100%",
                ...styles,
            }}
        />
    )

    useEffect(() => {

        if (tourDetails && isLoading === false) {
            setTour(tourDetails);
        }
    }, [tourDetails, isLoading]);

    useEffect(() => {
        if (suggestedTours !== null) {
            const toursResommented = suggestedTours?.tourList.map((tour) => {
                return {
                    title: tour.name,
                    description: tour.shortDescription,
                    imageUrl: tour.cover,
                    rate: tour.scores.score,
                    reviews: tour.reviewsTotal,
                    city: {
                        slug: tour.city.slug,
                    },
                    slug: tour.slug,
                }
            });
            setRecommendations(toursResommented);
        }
    }, [suggestedTours]);

    useEffect(() => {
        //setPaxRates
        if (tour?.paxRates) {
            const paxRates = tour.paxRates.map((item) => {
                return {
                    title: item.pax,
                    subtitle: item.age_range,
                    price: item.price,
                    value: item.value,
                }
            });
            setParticipans(paxRates);
            setBreadcrumbs([
                {
                    name: `Tours en ${tour?.city.name}`,
                    path: `/tours/${tour.city.slug}`
                },
                {
                    name: tour?.name,
                    path: '#'
                }
            ])
            setDate(dayjs(new Date()).add(tour?.cutoff.value, 'hours'));
            setFetchParams({ city: tour?.city.slug, limit: 3 });
        }


    }, [tour])


    const getTextLanguages = (languages) => {
        if (languages === undefined) return ''
        return languages.reduce((accumulator, currentValue, index) => {
            if (index !== 0) {
                return accumulator + ", " + currentValue?.language
            }
            return accumulator + currentValue?.language
        }, "");
    };

    const { isMobile } = useScreenDetector()

    const hashTags = topActivities.slice(0, 3)
    const parent = tourData.breadCrums[tourData.breadCrums.length - 1];
    const tourCards = dataPage.activities.slice(0, 3);
    const durationFilters = [
        "3-5 horas",
        "7-9 horas",
        "12-14 horas",
        "Indiferente",
    ]

    // const breadcrumbs = [
    //      {
    //         name: "Tours Cancun",
    //         path: '/tour/cancun'
    //     },
    //     {
    //         name: tour?.name,
    //         path: "#"
    //     }
    // ]




    const onExpandTab = (tab) => {
        if (expandedTabs.includes(tab)) {
            setExpandedTabs(current => current.filter(item => item !== tab))
        } else {
            setExpandedTabs(current => [...current, tab])
        }
    }

    const onClickItinerary = (name) => {
        if (addedItineraries.find((item) => item.title === name)) {
            setAddedItineraries(current => current.filter(item => item.title !== name))
        } else {
            const found = tourData.itinerary.find((item) => item.title === name)
            setAddedItineraries(current => [...current, found])
        }
    }

    const handleChangeCalendar = (newDate) => {
        setDate(newDate)
    }

    const onModifyParticipants = (instruction) => (title) => {
        setParticipans(current => {
            const incremented = current.map(item => {
                if (item.title === title) {
                    const newValue = instruction === "add" ?
                        ++item.value :
                        --item.value
                    return {
                        ...item,
                        value: newValue,
                    }
                } else {
                    return item
                }
            })
            return incremented
        })
    }

    useEffect(() => {
        const { REACT_APP_BASE_URL } = process.env
        const token = localStorage.getItem('token');

        const paxConfig = participants.map(item => ({ paxtype: item.title, pax_total: item.value }));

        axios.post(
            `${REACT_APP_BASE_URL}/index.php?r=api/quote&tour=${tour?.slug}`,
            {
                "tour_quote": [
                    {
                        "pax": paxConfig,
                        date_tour: dayjs(date).format('YYYY-MM-DD') // "2024-12-26"
                    }
                ]
            },
            {
                headers: {
                    'x-authorization-token': token,
                    'Content-Type': 'application/json'  // Especificamos que estamos enviando JSON
                }
            }
        ).then(({ data }) => {
            const { tour } = data;
            const { quote } = tour[0];
            if (!quote) setAvailableDate(false);
            if (!quote) return;


            setAvailableDate(true);
            setQuote(data);
        });

    }, [date])



    return (
        error ? <ErrorPage /> :
            <MainLayout>

                <Box sx={{
                    backgroundColor: "#ffffff",
                    height: "fit-content",
                    width: "100%",
                }}>
                    <Container>
                        {(breadcrumbs.length > 0) && <Breadcrumbs breadcrumbs={breadcrumbs} />}
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "1rem",
                            mb: { xs: "1rem", md: "2rem" },
                        }}>
                            <Box sx={{ width: "70%" }}>
                                <Typography
                                    component="h1"
                                    sx={{
                                        color: "#3E3A39",
                                        fontSize: { xs: 20, md: 32 },
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {tour?.name}
                                </Typography>
                                <Typography sx={{ display: { xs: "none", md: "block" }, mt: "1rem" }}>
                                    {tour?.content?.short_description}
                                </Typography>
                                <Box sx={{ display: { xs: "none", md: "block" }, mt: "1rem" }} >
                                    <ReviewRatings scores={tour?.scores} reviewsTotal={tour?.reviewsTotal} />
                                </Box>
                            </Box>
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "30%",
                                textAlign: "right",
                                pl: "15%",
                            }}>
                                <Typography
                                    color="secondary"
                                    component="p"
                                    sx={{
                                        fontSize: { xs: 20, md: 24 },
                                        fontFamily: 'Poppins',
                                        fontWeight: 700,
                                        lineHeight: 1.5,
                                    }}
                                >
                                    {`Desde ${tour?.rates?.min_price}${tour?.rates?.currency}`}
                                </Typography>
                                <AvailabilityButton styles={{ display: { xs: "none", md: "block" } }} />
                            </Box>
                        </Box>
                        <Box sx={{ mb: { xs: "1rem", md: "3rem" } }}>
                            {tourDetails !== undefined && <GridGallery images={tour.images} />}
                        </Box>
                        <Typography sx={{ display: { xs: "block", md: "none" } }}>
                            {tour?.content?.description}
                        </Typography>
                        <Box sx={{ display: { xs: "block", md: "none" }, mt: "1rem" }} >
                            <ReviewRatings rate={tourData.rate} reviewsCount={reviews.count} />
                        </Box>
                        <AvailabilityButton styles={{ display: { xs: "block", md: "none" }, mb: "2rem" }} />
                        <Box sx={{
                            display: "flex",
                            flexDirection: { xs: "column", md: "row" },
                            width: "100%"
                        }}>
                            <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                                <DetailsSection
                                    title="Sobre el tour"
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes("Sobre el tour")
                                    }
                                    onCollapse={onExpandTab}
                                >
                                    {tour?.content?.policyCancellation && <Box sx={{ my: "2rem" }}>
                                        <Box sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            mb: "1rem",
                                        }}>
                                            <ClockIcon width={24} height={24} />
                                            <Typography color="secondary" variant="subtitle3" sx={{ ml: "4px" }}>
                                                {
                                                    tourData.freeCancelation ?
                                                        "Cancelación gratuita" :
                                                        "Cancelación sin reembolso"
                                                }
                                            </Typography>
                                        </Box>
                                        <RichText
                                            styles={{ wfontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.policyCancellation}
                                        />
                                    </Box>}
                                    <Box sx={{ mb: "2rem" }}>
                                        <Box sx={{
                                            alignItems: "center",
                                            display: "flex",
                                            flexDirection: "row",
                                            mb: "1rem",
                                        }}>
                                            <GlobeIcon width={24} height={24} />
                                            <Typography color="secondary" variant="subtitle3" sx={{ ml: "4px" }}>
                                                Idioma: {getTextLanguages(tour?.languages)}
                                            </Typography>
                                        </Box>
                                        {/* <Typography sx={{ fontSize: 16, fontFamily: 'Inter' }}>
                                            Lorem ipsum dolor sit ametddd consectetur. Placerat quisque elementum viverra quis pellentesque mi. Congue maecenas ut.
                                        </Typography> */}
                                    </Box>
                                </DetailsSection>
                                <DetailsSection
                                    title="Detalles del tour"
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes("Detalles del tour")
                                    }
                                    onCollapse={onExpandTab}
                                >
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        mt: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            Descripción 📖
                                        </Typography>
                                        <Box sx={{ width: { xs: "100%", md: "70%" } }}>
                                            <RichText
                                                styles={{ wfontSize: 16, fontFamily: 'Inter' }}
                                                text={tour?.content?.description}
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        mt: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            Qué incluye ✅
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.include}
                                        />
                                    </Box>
                                    <Box sx={{
                                        borderBottom: "1px solid #666B6E",
                                        display: "flex",
                                        flexDirection: { xs: "column", md: "row" },
                                        my: "2rem",
                                        width: "100%",
                                        pb: "1rem",
                                    }}>
                                        <Typography component="h3" color="secondary" sx={{
                                            fontSize: 16,
                                            width: { xs: "100%", md: "30%" },
                                        }}>
                                            Que NO incluye ⛔
                                        </Typography>
                                        <RichText
                                            styles={{ fontSize: 16, fontFamily: 'Inter' }}
                                            text={tour?.content?.notIncluded}
                                        />
                                    </Box>
                                </DetailsSection>
                                <DetailsSection
                                    title="Itinerario Sugerido"
                                    isExpanded={
                                        !isMobile ? true : expandedTabs.includes("Itinerario Sugerido")
                                    }
                                    onCollapse={onExpandTab}
                                >
                                    <Typography component="h2" sx={{
                                        color: "#223F7F",
                                        fontFamily: "Poppins",
                                        fontSize: "18px",
                                        fontWeight: 700,
                                    }}>
                                        Nombre de la actividad Extra
                                    </Typography>
                                    <Typography component="p" sx={{
                                        color: "#223F7F",
                                        fontFamily: "Poppins",
                                        fontSize: "15px",
                                        fontWeight: 400,
                                    }}>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat
                                    </Typography>
                                </DetailsSection>

                            </Box>

                            <Box ref={targetRef} sx={{ width: { xs: "100%", md: "30%" } }}>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    {tour?.availabilityDays &&
                                        <Calendar availableDays={tour?.availabilityDays.days} title="Seleccione una fecha" date={date} handleChange={handleChangeCalendar} />
                                    }
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    <AddItemsButtonsFilter
                                        title="Participantes"
                                        options={participants}
                                        onAdd={onModifyParticipants("add")}
                                        onRemove={onModifyParticipants("remove")}
                                    />
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    <RadioButtonsFilter title="Duración" options={durationFilters} onChange={() => { }} />
                                </Box>
                                <Box sx={{ mt: { xs: "1rem", md: "2rem" }, pl: { xs: 0, md: "2rem" } }}>
                                    {availableDate ? <>
                                        {participants && <PaymentSummary items={participants} iva={13} tour={tour} date={date} />}
                                    </> : <>
                                        <Alert severity="error">No hay disponibilidad para la fecha seleccionada</Alert>
                                    </>}
                                </Box>

                            </Box>
                        </Box>
                        <Typography component="h2" color="secondary" sx={{
                            fontSize: { xs: 24, md: 32 },
                            fontFamily: 'Poppins',
                            fontWeight: 'bolder',
                            lineHeight: 1.5,
                            mt: { xs: "3rem", md: "4rem" },
                            mb: { xs: "1rem", md: "2rem" },
                        }}>
                            Otras sugerencias
                        </Typography>
                        <Box sx={{
                            display: "grid",
                            gridTemplateColumns: { xs: "1", md: "repeat(3, 1fr)" },
                            gap: "1rem",
                            width: "100%",
                        }}>

                            {recommendations && recommendations.map((tour, index) => (
                                <Link style={{
                                    textDecoration: 'none',
                                    display: "flex",
                                    justifyContent: "center",

                                }} key={index} onClick={handleScrollToTop} to={`/tour/${tour.city.slug}/${tour.slug}`}>
                                    <MediaCard
                                        key={`${tour.title}${index}`}
                                        {...tour}
                                    />
                                </Link>
                            ))}
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: "2rem"
                        }}>
                            <TagButton
                                text={
                                    isMobile ?
                                        "Ver más" :
                                        `Ver todos los tour disponibles en ${tour?.city?.name}`
                                }
                                href={`/tours/${tour?.city?.slug}`}
                                color="white"
                                sx={{
                                    borderRadius: 8,
                                    border: "1px solid #40C5E8",
                                    color: "#40C5E8",
                                    px: "1rem",
                                    py: ".4rem",
                                    marginBottom: "3rem",
                                }}
                            />
                        </Box>
                    </Container>
                </Box>
            </MainLayout>
    )
}

export default Tour
