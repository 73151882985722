import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchSuggestTours = (params) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!params) return; // Salir si no hay parámetros

        const fetchData = async () => {
            setLoading(true);
            const { REACT_APP_BASE_URL } = process.env;
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${REACT_APP_BASE_URL}/index.php`, {
                    params: {
                        r: 'api/tours/suggests',
                        city: params.city,
                        limit: params.limit,
                    },
                    headers: {
                        'x-authorization-token': token,
                        'Content-Type': 'application/json',
                    },
                });
                setData(response.data); // Axios maneja JSON automáticamente
            } catch (error) {
                console.error('Error fetching suggested tours:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [params]);

    return data;
};

export default useFetchSuggestTours;
