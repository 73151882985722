import React from 'react'
import { Container } from '@mui/material'
import CardsTab from '../../components/CardsTab'
import CarouselIconCards from '../../components/CarouselIconCards'
import GuidesBanner from '../../components/GuidesBanner'
import Hero from '../../components/Hero'
import ImagesGrid from '../../components/ImagesGrid'
import ReviewsCarousel from '../../components/ReviewsCarousel'
import { opacities } from '../../components/BlurImageWrapper'
import { selectTours } from '../../app/features/cartSlice'
import {
    getCardsByTours,
    dataCarousel
} from '../../utils/data'
import './styles.css'
import { useSelector } from 'react-redux'
import MainLayout from '../../components/MainLayout'
import { ReactComponent as TrekzyPattern } from '../../assets/images/trekzy-pattern.svg'
import useFetchDestinationsFeatured from '../../hooks/API/Destinations/useFetchDestinationsFeatured'
import homeBgImage from '../../assets/images/hero.png'


const Home = () => {
    const { destinationsFeatured: destinationsData, isLoading: isDestinationsLoading } = useFetchDestinationsFeatured();
    const tours = useSelector(selectTours)
    const cardTabs = [
        {
            name: 'Playa',
            data: [...getCardsByTours('Playa')]
        },
        {
            name: 'Montaña',
            data: [...getCardsByTours('Montaña')]
        },
        {
            name: 'Ciudad',
            data: [...getCardsByTours('Ciudad')]
        },
        {
            name: 'Rural',
            data: [...getCardsByTours('Rural')]
        }
    ]


    return (
        <MainLayout>
            <Hero
                title="¿Empezamos?"
                text="Tours privados a medida en más de 400 destinos."
                subText="Empieza a personalizar tu viaje."
                footerTitle="Tours privados a medida"
                footerText="Más de 400 destinos y 1000 actividades te esperan. Personaliza tus viajes a tu manera: museos, experiencias únicas y tours por las ciudades más emblemáticas. Haz que cada aventura sea especial."
                placeHolderText="Busca un destino o tour"
                bgImage={homeBgImage}
                opacity={opacities.dark}
                renderVector
            />
            <Container>
                <section className="section">
                    <CarouselIconCards />
                </section>
            </Container>
            <section className="section pattern-container">
                <Container>
                    {!isDestinationsLoading && <ImagesGrid
                        title="Elige los destinos favoritos"
                        text="Basado en las opiniones de otros clientes"
                        itemData={destinationsData}
                    />}
                    <div className='pattern-img' />
                </Container>
            </section>
            <section className="section">
                <CardsTab tabData={cardTabs} />
            </section>
            <section>
                <GuidesBanner />
                <ReviewsCarousel data={dataCarousel} />
            </section>
        </MainLayout>
    )
}

export default Home
