/* eslint-disable no-undef */
import React
    from 'react';
import {
    Box,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Link,
    Typography,
} from '@mui/material';
import './productCard.css';
import { ReactComponent as XSquare } from '../../assets/icons/square-cancel.svg'
import { ReactComponent as Clock } from '../../assets/icons/clock.svg'
import { ReactComponent as Globe } from '../../assets/icons/globe.svg'

const ProductCard3 = ({ imageUrl, description, path, price, rate, reviews, title }) => {

    return (
        <Link href={path} sx={{ textDecoration: "none" }}>
            <Card
                sx={{
                    width: '100%',
                    maxHeight: 420,
                    boxShadow: 10,
                    borderRadius: 2,
                    p: { xs: 0, md: ".8rem" },
                }}>
                <CardMedia
                    sx={{
                        height: '10rem',
                        borderRadius: '5px',
                        borderBottomLeftRadius: { xs: 0, md: "5px" },
                        borderBottomRightRadius: { xs: 0, md: "5px" }
                    }}
                    image={imageUrl}
                    title={title}
                />
                <Box
                    sx={{
                        width: '100%',
                        px: { xs: ".8rem", md: 0 },
                        pb: { xs: ".8rem", md: 0 },
                    }}>
                    <CardContent sx={{
                        px: 0
                    }}>
                        <Typography
                            variant="h5"
                            sx={{ fontSize: '16px' }}
                        >{title}</Typography>
                        <Typography
                            variant='body2'
                            component='p'
                            sx={{
                                display: '-webkit-box',
                                overflow: 'hidden',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: "vertical",
                            }}
                        >{description}</Typography>
                    </CardContent>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        mb: "1rem",
                        p: 0,
                    }}>


                    </Box>
                    <CardActions sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        p: 0,
                    }}>

                        <Box sx={{
                            alignItems: "center",
                            display: 'flex',
                            flexDirection: "row",
                            gap: { xs: 1, md: "2px" },
                        }}>
                            <Box
                                sx={{
                                    px: ".8rem",
                                    py: "2px",
                                    lineHeight: 'normal',
                                    borderRadius: 1,
                                    bgcolor: 'secondary.main',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    color="primary.contrastText"
                                    sx={{
                                        fontSize: '16px',
                                        fontWeight: 'bold'
                                    }}>
                                    {rate}
                                </Typography>
                            </Box>

                            <Typography
                                fontFamily={'Poppins'}
                                sx={{
                                    lineHeight: '1.9',
                                    fontSize: { xs: '12px', md: '14px' }
                                }}>
                                {`(${reviews} Reviews)`}
                            </Typography>
                        </Box>
                    </CardActions>
                </Box>
            </Card>
        </Link>
    )
}

export default ProductCard3
